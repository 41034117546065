.site-layout-sub-header-background {
    background: #fff;
}

.site-layout-background {
    background: #fff;
    min-height: 100%;
}
.logo {
    height: 64px;
    background: url("img/logo-sbl-3.png") no-repeat;
    background-size: contain;
    background-position: center;
    background-color: white;
}
.AdminLayout .ant-layout-header {
    background: white !important;
}
.ant-layout-sider {
    background: #19bb4f !important;
}
.ant-menu-item:not(.ant-menu-item-selected) {
    color: #fff;
    background-color: #19bb4f;
}
.ant-menu-item:not(.ant-menu-item-selected):hover {
    color: #fff;
    background-color: rgba(19, 121, 51, 1);
}
.ant-menu-item-selected {
    color: #19bb4f !important;
}
.ant-menu-inline .ant-menu-item::after {
    border-right: 0px !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fff !important;
}
.ant-menu {
    font-size: 20px !important;
}
#components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}
.ant-layout-header {
    display: flex;
    align-items: center;
    padding: 0 24px !important;
}
.ant-layout-header .content {
    display: flex;
    justify-content: end;
    flex-grow: 1;
}
/*.ant-message-notice-content {*/
/*    max-height: 58px;*/
/*    overflow-y: hidden;*/
/*    white-space: pre-line;*/
/*    text-overflow: clip;*/
/*}*/

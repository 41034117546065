.Auth .ant-layout-content {
    background-color: #f7f8fa;
    padding-top: 13vh;
    height: calc(100vh - 64px);
}
.App .Auth .ant-layout {
    min-height: calc(100vh - 64px);
}
.Auth .ant-typography {
    text-align: center;
}

.Auth h4.ant-typography {
    margin-bottom: 30px;
    font-weight: normal;
}

.Auth .ant-form-item-control-input input,
.Auth .ant-form-item-control-input button {
    border-radius: 7px;
}

.Auth .ant-btn-primary:active,
.Auth .ant-btn-primary:hover,
.Auth .ant-btn-primary:focus,
.Auth .ant-btn-primary {
    background-color: #209f38;
}

.Auth .ant-input:hover,
.Auth .ant-input:focus,
.Auth .ant-input-focused,
.Auth .ant-btn-primary,
.Auth .ant-form-item-control-input input,
.Auth .ant-form-item-control-input button,
.Auth .ant-btn-primary:active,
.Auth .ant-btn-primary:focus,
.Auth .ant-btn-primary:hover {
    border-color: #209f38;
}
.Auth .ant-input[disabled],
.Auth .ant-btn[disabled] {
    background: #f5f5f5;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
}
.Auth .ant-form-item-label {
    text-align: left;
}
.Auth .ant-image {
    z-index: 1;
}
@media (max-width: 575px) {
    .Auth .ant-form .ant-form-item .ant-form-item-label,
    .Auth .ant-form .ant-form-item .ant-form-item-control,
    .Auth .ant-form .ant-form-item .ant-form-item-control.ant-col-xs-18 {
        flex: 0 0 75%;
        max-width: 75%;
    }
}

.sberId img {
    margin-right: 0.5rem;
}

.Profile button.sberId {
    border-radius: 7px;
}

.Auth .withSberId, .Auth .withSberId:hover, .Auth .withSberId:focus {
    background-color: #E6F7FF !important;
    border-color: #1890FF !important;
    color: #1890FF;
}

.tinkoffId {
    background-color: #FFDD2D !important;
    border-color: #FFDD2D !important;
    color: #262626 !important;
}

.tinkoffId img {
    height: 20px;
    margin-left: 0.5rem;
}

.tid-4PNRE-button-primary {
    width: 100%;
}
.tid-4PNRE-button-primary, .tid-4PNRE-text-primary {
    font-size: 16px !important;
    color: #262626 !important;
}
.tid-4PNRE-button-primary:hover {
    background-color: #FFDD2D !important;
}

.etcBlock {
    margin-bottom: 1rem;
}

.modalAuthPhone .message {
    text-align: center;
    color: #838383;
    margin-bottom: 1rem;
}

.modalAuthPhone button {
    margin-top: 1rem;
}
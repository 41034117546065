.PolicyScreen .ant-page-header-ghost {
    padding-left: 0;
}
.PolicyScreen .ant-page-header-heading-title {
    font-weight: normal;
    color: #209f38;
    white-space: normal;
    text-align: start;
}

.PolicyScreen .ant-form-item-control-input input,
.PolicyScreen .ant-form-item-control-input button {
    border-radius: 7px;
}

.PolicyScreen .ant-btn-primary,
.PolicyScreen .ant-form-item-control-input button,
.PolicyScreen .ant-btn-primary:active,
.PolicyScreen .ant-btn-primary:hover {
    border-color: #209f38;
    background-color: #209f38;
}

.PolicyScreen .continue-button.ant-btn-lg {
    height: 55px;
    font-size: 24px;
    width: 100%;
}

.PolicyScreen div.ant-typography {
    text-align: start;
    margin-bottom: 0;
}
.PolicyScreen h4.ant-typography {
    text-align: start;
    margin: -20px 0 5px;
    font-size: 16px;
}
.PolicyScreen .ant-checkbox-wrapper {
    padding-left: 25px;
    text-align: start;
    line-height: 24px;
}
.PolicyScreen .ant-checkbox {
    margin-left: -25px;
}
.PolicyScreen .ant-form-item-explain.ant-form-item-explain-error {
    padding: 0 0 10px 25px;
}
.PolicyScreen .align-start {
    align-items: start;
}
.PolicyScreen .document {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    padding: 4px 11px;
    border-radius: 3px;
    white-space: pre-line;
}
@media (max-width: 575px) {
    .ant-form .ant-form-item .ant-form-item-control.ant-col-xs-18 {
        flex: 0 0 75%;
        max-width: 75%;
    }
}

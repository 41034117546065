.MobileHeader.ant-layout-header {
    background-color: #f7f8fa;
    display: flex;
    align-content: center;
    justify-content: center;
    height: 75px;
    margin-bottom: 2rem;
}

.MobileHeader .ant-image-img {
    max-height: 200px;
    max-width: 100%;
    min-width: 250px;
    padding-top: 30px;
    width: 80%;
}

@media (min-width:801px) {
    .MobileHeader.ant-layout-header {
        margin-top: 1rem;
    }
}
.Success div.ant-typography {
    text-align: start;
}
.Success .ant-form-item-control-input button {
    border-color: #209f38;
    background-color: #209f38;
}
.Success .ant-btn-background-ghost.ant-btn-primary,
.Success .ant-btn-background-ghost.ant-btn-primary:active,
.Success .ant-btn-background-ghost.ant-btn-primary:hover,
.Success .ant-btn-background-ghost.ant-btn-primary:focus {
    border-color: #209f38;
    color: #209f38;
}
.Success .margin-top-30 {
    margin-top: 30px;
}

.NewActTemplate .tagify--mix .tagify__input {
    height: 500px;
    overflow: auto;
}
.NewActTemplate .ant-tag {
    margin-bottom: 4px;
}
.NewActTemplate .ant-form-item.custom-margin {
    margin-bottom: 18px;
}
.document {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    padding: 4px 11px;
    border-radius: 3px;
    white-space: pre-line;
    text-align: start;
    margin: 0 20px 20px 20px;
}
.NewActTemplate .ant-form-item-label > label {
    align-items: baseline;
}

.document-m0 {
    margin: 0 !important;
    padding-bottom: 2rem !important;
    display: flex;
    flex-direction: column;
}
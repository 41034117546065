.ProfileEditInfoWrapper {
    padding: 0 40px 20px;
}

.ProfileEditInfoWrapper .ant-btn {
    padding: 0;
    border-radius: 7px;
}

.ProfileEditInfoWrapper .ant-page-header {
    padding-left: 0;
}

.ProfileEditInfoWrapper .ant-page-header .ant-page-header-heading-title {
    font-size: 15px;
}

.ProfileEditInfoWrapper .ant-form-item-explain {
    text-align: left;
}

.ProfileEditInfoWrapper .info {
    text-align: left;
    margin-bottom: 1rem;
}
.myTaxApp {
    margin-bottom: 3rem;
}

.myTaxApp h4, .myTaxApp p {
    text-align: left;
}

.myTaxApp h4 {
    margin-top: 2rem !important;
}

.myTaxApp ul {
    list-style: decimal;
    padding-left: 0;
    text-align: left;
}

.myTaxApp ul li {
    text-align: left;
}

.myTaxAlert ul {
    padding-left: 1rem;
}

.myTaxAlert div {
    text-align: center;
}

.myTaxAlert div button {
    padding: 6px 16px !important;
}
.filter-input {
    min-width: 272px;
    width: 272px;
}
.ant-divider-horizontal.dropdown-divider {
    margin: 4px 0
}
.dropdown-footer {
    padding: 8px;
}

.buttonChangePhone {
    text-align: center;
}

.modalChangePhone {
}

.modalChangePhone h1 {
    text-align: center;
}

.modalChangePhone .message {
    text-align: center;
    color: #838383;
    margin-bottom: 2rem;
}

.modalChangePhone button {
    margin-top: 1rem;
}

.modalChangePhone .ant-form-item-control-input input {
    border-radius: 7px;
}

.modalChangePhone .buttonSend, .modalChangePhone .buttonSend:hover {
    background-color: #E6F7FF;
    border-color: #1890FF !important;
    color: #1890FF;
    border-radius: 7px;
}

.modalChangePhone .ant-modal-content {
    border-radius: 16px;
}
.ProfileWrapper h4.ant-typography {
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: normal;
}
.ProfileWrapper .steps-content {
    margin-top: 16px;
}
.ProfileWrapper .ant-steps-item-icon {
    width: 48px;
    height: 48px;
    line-height: 46px;
    font-size: 24px;
}

.ProfileWrapper .ant-steps-vertical > .ant-steps-item:last-of-type .ant-steps-item-content {
    width: 0;
}
.ProfileWrapper .ant-spin-blur {
    opacity: 0;
}
.ProfileWrapper.ant-layout {
    padding: 0 40px;
}
.ProfileWrapper .ant-form-item-explain {
    text-align: left;
}
.ProfileWrapper .offer .ant-form-item-explain {
    text-align: center;
    margin-bottom: 10px;
}
.ProfileWrapper .danger-step .ant-steps-item-active .ant-steps-item-icon {
    background-color: red;
    border-color: red;
}
/*
* Horizontal styles for mobile steps
*/
.App .ant-steps-item-finish .ant-steps-item-icon,
.App .ant-steps-item-process .ant-steps-item-icon{
    border-color: #209f38;
}
.App .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #209f38;
    border-color: #209f38;
}
.App .ant-steps-item-process .ant-steps-item-icon,
.App .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon,
.App .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #209f38;
    border-color: #209f38;
}
.App .ant-steps-vertical > .ant-steps-item .ant-steps-item-icon,
.App .ant-steps-item-icon {
    margin: 0;
}
.App .ant-steps-item-title::after {
    left: 0;
    top: 24px;
}
.App .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 0;
}
.App .ant-upload-list-picture .ant-upload-list-item-name-icon-count-1 {
    padding-right: 32px;
}
.App .ant-upload-list-item-card-actions.picture {
    top: 19px;
    right: 5px;
}
.App .ant-upload-list-item-card-actions .anticon {
    color: rgba(255, 50, 50, 0.7);
    padding: 5px;
}

@media (max-width: 580px) {
    .ProfileWrapper .ant-steps-vertical {
        flex-direction: row;
    }
    .ProfileWrapper .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        display: block;
    }
    .ProfileWrapper .ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
        display: none;
    }
    .ProfileWrapper .ant-steps-vertical .ant-steps-item:last-child {
        flex: none;
    }
    .ProfileWrapper .ant-steps-vertical .ant-steps-item-icon {
        margin-right: 0;
    }
}

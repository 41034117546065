.App {
  text-align: center;
  font-family: 'TT Norms','Helvetica Neue',Helvetica,Arial,sans-serif;
}

.App-link {
  color: #61dafb;
}

.App .ant-layout {
  background-color: #f7f8fa;
  min-height: 100vh;
}
.ant-btn.ant-btn-success {
  color: #fff;
  background: #19bb4f;
  border-color: #19bb4f;
}
.ant-btn.ant-btn-success:focus,
.ant-btn.ant-btn-success:hover {
  background: rgba(25, 187, 79, 0.75);
  border-color: rgba(25, 187, 79, 0.75);
}
.ant-modal-wrap .ant-btn > .ant-btn-loading-icon .anticon,
.ant-drawer .ant-btn > .ant-btn-loading-icon .anticon,
.App .ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
}
.ant-modal-wrap .ant-btn > .ant-btn-loading-icon,
.ant-drawer .ant-btn > .ant-btn-loading-icon,
.App .ant-btn > .ant-btn-loading-icon {
  padding-right: 8px;
}
.mt-4 {
  margin-top: 30px;
}
.mt-2 {
  margin-top: 5px;
}
.mt-3 {
  margin-top: 10px;
}
.ml-2 {
  margin-left: 5px;
}
.ml-3 {
  margin-left: 10px;
}
.mr-3 {
  margin-right: 10px;
}
.mb-3 {
  margin-bottom: 10px;
}
.hide.ant-btn,
.hide {
  display: none;
}
.text-align-left {
  text-align: left;
}
.break-word {
  word-wrap: break-word;
  word-break: break-word;
}
.d-flex {
  display: flex;
}

.w-150 {
  width: 150px;
}
.w-100 {
  width: 100%;
}
a[disabled] {
  pointer-events: none;
}

.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0;
}
.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text {
  padding-left: 0;
  color: #209f38;
}

/* запретить показ браузером стрелок верх-низ */
input[type="number"].noScrollNumber::-webkit-outer-spin-button,
input[type="number"].noScrollNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"].noScrollNumber {
  -moz-appearance: textfield;
}
input[type="number"].noScrollNumber:hover,
input[type="number"].noScrollNumber:focus {
  -moz-appearance: number-input;
}
input[type=number].noScrollNumber::-webkit-inner-spin-button,
input[type=number].noScrollNumber::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-message {
  z-index: 3000;
}

.wrapAble div {
  flex-wrap: wrap;
}

.wrapCheckIcons {
  position: relative;
  right: 48px;
  text-align: right;
  width: 70px;
}

.wrapCheckIcons svg {
  margin-left: 3px;
}

.error, .error:hover {
  border-color: #ff4d4f !important;
}
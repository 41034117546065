.authFillButtons {
    width: 100%;
    border-collapse: collapse;
    border: 0;
    margin-top: 0.5rem;
}

.authFillButtons td {
    width: 50%;
}

.authFillButtons td.left {
    padding-right: 0.5rem;
}
.authFillButtons td.right {
    padding-left: 0.5rem;
}
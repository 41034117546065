.innPhotoDescription p {
    text-align: left;
}

.innPhotoDescription img {
    max-height: 400px;
    margin-bottom: 1rem;
}

form .ant-upload {
    width: 100%;
}

/*form .ant-form-item-control-input .ant-upload button {*/
    /*border-color: #FFDD2D !important;*/
    /*background-color: #FFDD2D !important;*/
/*}*/
form .ant-form-item-control-input .ant-upload button:hover:not(:disabled) {
    color: rgb(32, 159, 56, 0.75);
    border-color: rgb(32, 159, 56, 0.75);
    background-color: rgb(255, 255, 255, 0.75);
}
form .ant-form-item-control-input .ant-upload-list button {
    background-color: transparent !important;
    border-color: transparent !important;
}

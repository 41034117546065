.bankAccept {

}
.bankAccept .ant-alert-content {
    text-align: left;
}

.bankAccept .bankAcceptForm {
    margin-top: 1rem;
}

.bankAccept .ant-btn-primary, .bankAccept .ant-btn-primary:hover {
    background-color: #209f38;
    border-color: #209f38;
}
.ClothingSize {
    padding: 0 20px;
}
.ClothingSize .ant-btn-primary,
.ClothingSize .ant-btn-primary:active,
.ClothingSize .ant-btn-primary:focus,
.ClothingSize .ant-btn-primary:hover {
    border-color: #209f38;
    background-color: #209f38;
}

.ClothingSize .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 7px;
    text-align: start;
}

.Profile .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 7px;
    text-align: start;
}

.Profile .ant-form .ant-btn.margin-top-30 {
    margin-top: 30px;
}

.Profile .ant-page-header-ghost {
    padding-left: 0;
}
.Profile .ant-page-header-heading-title {
    font-weight: normal;
    color: #209f38;
}

.Profile .ant-form-item-control-input input,
.Profile .ant-form-item-control-input button, .Profile .ant-select-selector {
    border-radius: 7px;
}

.Profile .ant-btn-primary,
.Profile .ant-form-item-control-input button,
.Profile .ant-btn-primary:active,
.Profile .ant-btn-primary:hover,
.Profile .ant-btn-primary:focus {
    border-color: #209f38;
    background: #209f38;
}
.Profile .ant-btn-background-ghost.ant-btn-primary,
.Profile .ant-btn-background-ghost.ant-btn-primary:active,
.Profile .ant-btn-background-ghost.ant-btn-primary:hover,
.Profile .ant-btn-background-ghost.ant-btn-primary:focus {
    border-color: #209f38;
    color: #209f38;
    background: white;
}
.Profile .full-width .ant-upload {
    width: 100%;
}
.Profile .ant-upload-list-item .ant-btn-icon-only.ant-upload-list-item-card-actions-btn {
    border: none;
    background-color: transparent;
}
/*.Profile .ant-upload .ant-btn {*/
    /*background-color: #fecb00;*/
    /*border-color: #fecb00;*/
    /*color: #fff;*/
/*}*/
.Profile .ant-upload .ant-btn[disabled] {
    background: #f5f5f5;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
}

.Profile .continue-button.ant-btn-lg {
    /*height: 55px;*/
    /*padding: 6px 75px;*/
    font-size: 18px;
}

.Profile .ant-upload-text-icon {
    text-align: left;
}

.Profile .ant-alert {
    text-align: left;
}

.Profile .label-row .full-width {
    width: 100%;
    position: absolute;
    padding-left: 14px;
}
.Profile .label-row label:not(.ant-checkbox-wrapper) {
    width: 100%;
    min-height: 10px;
}
